import {Injectable} from '@angular/core';
import {PartFilter} from './models/filter/partfilter';
import {Part} from './models/part';

import {
  AssemblyControllerService,
  AssemblyResponse,
  BlockingActionUpdateRequest,
  GetPartsV3RequestParams,
  PartAssemblyResponse,
  PartBlockedState,
  PartControllerService,
  PartControllerV2Service,
  PartControllerV3Service,
  PartInstallationStatus,
  PartNumberDefinitionControllerService,
  PartNumberDefinitionResponse,
  PartResponse,
  PartStatus, QuickTimeSelector,
  UpdateBlockingActionRequestParams
} from '@cstx/volkswagen-mqs-part-service-client';
import {firstValueFrom} from 'rxjs';
import {ErrorHandler} from '../error-handler/error-handler';
import {SlicePartResponse} from '@cstx/volkswagen-mqs-part-service-client/model/slicePartResponse'
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {BlockingActionResponse} from '@cstx/volkswagen-mqs-part-service-client/model/blockingActionResponse';
import {PagePartResponse} from '@cstx/volkswagen-mqs-part-service-client/model/pagePartResponse';
import {AuthService} from '../../../core/services/auth.service';
import {PartAssemblyResponseList} from '@cstx/volkswagen-mqs-part-service-client/model/partAssemblyResponseList';
import {BackendService} from './models/backend-service';
import {ConfigService} from '../../../core/services/config.service';
import {BackendStatus} from './models/BackendStatus';
import { StatusControllerService } from '@cstx/volkswagen-mqs-part-service-client';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PartService extends BackendService {

  constructor(private partControllerService: PartControllerService,
              private partControllerServiceV2: PartControllerV2Service,
              private partControllerServiceV3: PartControllerV3Service,
              private partNumberDefinitionControllerService: PartNumberDefinitionControllerService,
              private assemblyControllerService: AssemblyControllerService,
              private statusControllerService: StatusControllerService) {

    super('part-service', ConfigService.configuration.ptsApiUrl);

  }

  /**
   * This method returns a list of parts for the given filter.
   * @param filter A filter we want to get the parts for.
   * @return List of parts matching the given filter.
   */
  public async getParts(filter: PartFilter): Promise<Part[]> {
    const parts = new Array<Part>();
    try {
      const response = await this.getPartsV3Filter(filter);

      response.content.forEach(partResponse => {
        const part = new Part(partResponse);
        parts.push(part);
      });
    } catch (error) {
      ErrorHandler.printError(error);
    }
    return parts;
  }

  public async getStatus(): Promise<BackendStatus> {
    let backendStatus: BackendStatus = BackendStatus.UNKNOWN;

    try {
      const response = await firstValueFrom(this.statusControllerService.getStatus());

        if (response.status.toString() === 'OK/Running') {
          backendStatus = BackendStatus.UP;
        } else {
          backendStatus = BackendStatus.FAILED;
        }
    } catch (error) {
      LoggingService.logError(LoggingSource.PART_SERVICE,
        `Backend error occurred when trying get status from service.`, error);
    }

    return backendStatus;
  }

  public async getPartCountV3Filter(partFilter: PartFilter): Promise<number> {
    let mappedPartState;
    switch (partFilter.partState) {
      case 'COMPLETE':
        mappedPartState = PartStatus.Complete;
        break
      case 'PARTIAL':
        mappedPartState = PartStatus.Partial;
        break
      case 'INCOMPLETE':
        mappedPartState = PartStatus.Incomplete;
        break
      default:
        mappedPartState = undefined
    }
    return this.getPartCountV3(partFilter.parentId,
      partFilter.manufacturerCode,
      partFilter.partNumber,
      partFilter.partType,
      partFilter.dmc,
      partFilter.costCenter,
      undefined,
      partFilter.sequenceNumberFrom,
      partFilter.sequenceNumberTo,
      partFilter.productionDateFrom,
      partFilter.productionDateTo,
      undefined,
      undefined,
      mappedPartState,
      undefined,
      undefined,
      undefined,
      partFilter.partBlockedState,
      partFilter.quickTimeSelector,
      partFilter.currentPageIndex,
      partFilter.pagingSize,
      partFilter.sort,
      partFilter.humanReadableId)
  }

  public async getPartCountV3(parentId?: string,
                              manufacturerCodes?: Array<string>,
                              partNumbers?: Array<string>,
                              partType?: string,
                              dmc?: string,
                              costCenters?: Array<string>,
                              productionLine?: number,
                              sequenceNumberFrom?: number,
                              sequenceNumberTo?: number,
                              productionDateFrom?: string,
                              productionDateTo?: string,
                              buildDateFrom?: string,
                              buildDateTo?: string,
                              partState?: PartStatus,
                              partInstallationStatus?: PartInstallationStatus,
                              modifiedAtFrom?: string,
                              modifiedAtTo?: string,
                              partBlockedState?: PartBlockedState,
                              quickTimeSelector?: QuickTimeSelector,
                              page?: number,
                              size?: number,
                              sort?: Array<string>,
                              humanReadableId?: string): Promise<number> {
    const mappedFilterInput: GetPartsV3RequestParams = {
      manufacturerCodes: this.nullOrUndefinedToUndefined(manufacturerCodes),
      partNumbers: this.nullOrUndefinedToUndefined(partNumbers),
      partType: this.nullOrUndefinedToUndefined(partType),
      dmc: this.nullOrUndefinedToUndefined(dmc),
      costCenters: this.nullOrUndefinedToUndefined(costCenters),
      productionLine: this.nullOrUndefinedToUndefined(productionLine),
      sequenceNumberFrom: this.nullOrUndefinedToUndefined(sequenceNumberFrom),
      sequenceNumberTo: this.nullOrUndefinedToUndefined(sequenceNumberTo),
      productionDateFrom: this.nullOrUndefinedToUndefined(productionDateFrom),
      productionDateTo: this.nullOrUndefinedToUndefined(productionDateTo),
      buildDateFrom: this.nullOrUndefinedToUndefined(buildDateFrom),
      buildDateTo: this.nullOrUndefinedToUndefined(buildDateTo),
      partState: this.nullOrUndefinedToUndefined(partState),
      partInstallationStatus: this.nullOrUndefinedToUndefined(partInstallationStatus),
      modifiedAtFrom: this.nullOrUndefinedToUndefined(modifiedAtFrom),
      modifiedAtTo: this.nullOrUndefinedToUndefined(modifiedAtTo),
      partBlockedState: this.nullOrUndefinedToUndefined(partBlockedState),
      buildDateQuickTimeSelector: this.nullOrUndefinedToUndefined(quickTimeSelector),
      page: this.nullOrUndefinedToUndefined(page),
      size: this.nullOrUndefinedToUndefined(size),
      sort: this.nullOrUndefinedToUndefined(sort),
      humanReadableId: this.nullOrUndefinedToUndefined(humanReadableId)
    }
    return await firstValueFrom(this.partControllerServiceV3.countV3(mappedFilterInput));
  }

  public async getPartsV3Filter(partFilter: PartFilter): Promise<SlicePartResponse> {
    let mappedPartState;
    switch (partFilter.partState) {
      case 'COMPLETE':
        mappedPartState = PartStatus.Complete;
        break
      case 'PARTIAL':
        mappedPartState = PartStatus.Partial;
        break
      case 'INCOMPLETE':
        mappedPartState = PartStatus.Incomplete;
        break
      default:
        mappedPartState = undefined
    }
    return this.getPartsV3(partFilter.parentId,
      partFilter.manufacturerCode,
      partFilter.partNumber,
      partFilter.partType,
      partFilter.dmc,
      partFilter.costCenter,
      undefined,
      partFilter.sequenceNumberFrom,
      partFilter.sequenceNumberTo,
      partFilter.productionDateFrom,
      partFilter.productionDateTo,
      undefined,
      undefined,
      mappedPartState,
      undefined,
      undefined,
      undefined,
      partFilter.partBlockedState,
      partFilter.quickTimeSelector,
      partFilter.humanReadableId,
      partFilter.currentPageIndex,
      partFilter.pagingSize,
      partFilter.sort)
  }

  public async getPartsV3(parentId?: string,
                          manufacturerCodes?: Array<string>,
                          partNumbers?: Array<string>,
                          partType?: string,
                          dmc?: string,
                          costCenters?: Array<string>,
                          productionLine?: number,
                          sequenceNumberFrom?: number,
                          sequenceNumberTo?: number,
                          productionDateFrom?: string,
                          productionDateTo?: string,
                          buildDateFrom?: string,
                          buildDateTo?: string,
                          partState?: PartStatus,
                          partInstallationStatus?: PartInstallationStatus,
                          modifiedAtFrom?: string,
                          modifiedAtTo?: string,
                          partBlockedState?: PartBlockedState,
                          quickTimeSelector?: QuickTimeSelector,
                          humanReadableId?: string,
                          page?: number,
                          size?: number,
                          sort?: Array<string>): Promise<SlicePartResponse> {
    const mappedFilterInput: GetPartsV3RequestParams = {
      manufacturerCodes: this.nullOrUndefinedToUndefined(manufacturerCodes),
      partNumbers: this.nullOrUndefinedToUndefined(partNumbers),
      partType: this.nullOrUndefinedToUndefined(partType),
      dmc: this.nullOrUndefinedToUndefined(dmc),
      costCenters: this.nullOrUndefinedToUndefined(costCenters),
      productionLine: this.nullOrUndefinedToUndefined(productionLine),
      sequenceNumberFrom: this.nullOrUndefinedToUndefined(sequenceNumberFrom),
      sequenceNumberTo: this.nullOrUndefinedToUndefined(sequenceNumberTo),
      productionDateFrom: this.nullOrUndefinedToUndefined(productionDateFrom),
      productionDateTo: this.nullOrUndefinedToUndefined(productionDateTo),
      buildDateFrom: this.nullOrUndefinedToUndefined(buildDateFrom),
      buildDateTo: this.nullOrUndefinedToUndefined(buildDateTo),
      partState: this.nullOrUndefinedToUndefined(partState),
      partInstallationStatus: this.nullOrUndefinedToUndefined(partInstallationStatus),
      modifiedAtFrom: this.nullOrUndefinedToUndefined(modifiedAtFrom),
      modifiedAtTo: this.nullOrUndefinedToUndefined(modifiedAtTo),
      partBlockedState: this.nullOrUndefinedToUndefined(partBlockedState),
      humanReadableId: this.nullOrUndefinedToUndefined(humanReadableId),
      buildDateQuickTimeSelector: this.nullOrUndefinedToUndefined(quickTimeSelector),
      page: this.nullOrUndefinedToUndefined(page),
      size: this.nullOrUndefinedToUndefined(size),
      sort: this.nullOrUndefinedToUndefined(sort)
    }
    return await firstValueFrom(this.partControllerServiceV3.getPartsV3(mappedFilterInput))
  }


  /**
   * This method returns a part for the given id.
   * @param id The id we want to get part information for.
   * @return Promise<Part> A part object or null.
   */
  public async getPartByID(id: string): Promise<Part> {
    let part: Part = null;

    try {
      const response = await firstValueFrom(this.partControllerService.getPart({id}));
      part = new Part(response);
    } catch (error) {
      ErrorHandler.printError(error);
    }

    return part;
  }

  /**
   * This method returns the IDs of the parent components of the specified components.
   * @param idArray IDs of the components for which the parent components are being sought
   * @return Promise<Array<PartAssemblyResponseList>> or null.
   */
  public async getAssemblieByPartIDs(idArray: string[]): Promise<Array<PartAssemblyResponseList>> {
    return await firstValueFrom(this.partControllerService.getAssembliesByPartIdList({
      partListRequest: {
        ids: idArray
      }
    }, 'body'));
  }


  /**
   * This method returns a part for the given dmc. If multiple dmc where found in
   * returns the first one only!
   * @param dmc The data matrix code value we want to get part information for.
   * @return Promise<Part> A part object or null.
   */
  public async getPartByDMC(dmc: string): Promise<Part> {
    let part: Part = null;

    try {
      const response = await firstValueFrom(this.partControllerService.getPartByDmc({searchDmc: dmc.trimEndCharacter('_')}));
      if (response.length > 0) {
        part = new Part(response[0]);
      }
    } catch (error) {
      if (error instanceof HttpErrorResponse &&  (error as HttpErrorResponse).status === 404) {
        LoggingService.logWarning(LoggingSource.PART_SERVICE,
          `No data for dmc ${dmc} found.`, error);
        return;
      } else {
        LoggingService.logError(LoggingSource.PART_SERVICE,
          `Backend error occurred when trying to receive part infos via dmc`, error);
      }
    }

    return part;
  }


  /**
   * This method returns a part for the given id.
   * @param list The ids we want to get part information for.
   * @param pagingSize Size of the pages to get. Default 250.
   * @return Promise<Array<Part>> A part object array or an empty list.
   */
  public async getPartByIDList(list: string[], pagingSize: number = 250): Promise<Array<Part>> {
    let partsResponses = new Array<PartResponse>();
    let allPartsRequested = false;

    const parts = new Array<Part>();

    try {
      while (!allPartsRequested) {
        let partsToGet: string[];

        if (list.length > 0) {
          partsToGet = list.splice(0, pagingSize);

          const result = await firstValueFrom(this.partControllerService.getAllPartsByIDList({
            partListRequest: {
              ids: partsToGet
            },
            size: pagingSize
          }));

          if (partsResponses.length === 0) {
            partsResponses = result.content;
          } else {
            partsResponses.push(...result.content);
          }

        } else {
          allPartsRequested = true;
        }
      }

      partsResponses.forEach(p => {
        const part = new Part(p);
        parts.push(part);
      })
    } catch (error) {
      ErrorHandler.printError(error);
    }

    return parts;
  }

  /**
   * This method returns an assembly list for the given ids.
   * @param list The ids we want to get assembly information for.
   * @param pagingSize Size of the pages to get. Default 250.
   * @return Promise<Array<Part>> A assembly object array or an empty list.
   */
  public async getAssembliesByIDList(list: string[], pagingSize: number = 250): Promise<Array<AssemblyResponse>> {
    let assembliesResponses = new Array<AssemblyResponse>();
    let allAssembliesRequested = false;


    try {
      while (!allAssembliesRequested) {
        let assembliesToGet: string[];

        if (list.length > 0) {
          assembliesToGet = list.splice(0, pagingSize);

          const result = await firstValueFrom(this.assemblyControllerService.getAllAssembliesByIDList({
            assemblyListRequest: {
              ids: assembliesToGet
            },
            size: pagingSize
          }));

          if (assembliesResponses.length === 0) {
            assembliesResponses = result.content;
          } else {
            assembliesResponses.push(...result.content);
          }
        } else {
          allAssembliesRequested = true;
        }
      }
    } catch (error) {
      ErrorHandler.printError(error);
    }

    return assembliesResponses;
  }

  /**
   * This method returns a list of parent assemblies for the given id.
   * @param id The id we want to get parent assemblies for.
   * @return Promise<Array<PartAssemblyResponse>> A assembly object array or an empty list.
   */
  public async getAllParentAssemblies(id: string): Promise<Array<PartAssemblyResponse>> {
    let resultList = new Array<PartAssemblyResponse>();
    try {
      resultList = await firstValueFrom(this.partControllerService.getAssembliesByPartId({id}));
    } catch (error) {
      ErrorHandler.printError(error);
    }
    return resultList;
  }

  // TODO: Paul Breuker - bitte vergleichen mit dem Rest in dieser Klasse und dann mal schauen was hier alles fehlt.
  public getAssemblyById(id: string): Promise<AssemblyResponse> {
    return firstValueFrom(this.assemblyControllerService.getAssemblyById({id}));
  }

  public nullOrUndefinedToUndefined(val) {
    return val === null || val === undefined ? undefined : val
  }

  /**
   * This method returns a partNumberdefinition for a given partNumber combination.
   * @param partNumber The partNumber we search for.
   * @return Promise<PartNumberDefinitionResponse>
   */

  public async getPartNumberDefinitionByPartNumber(partNumber: string): Promise<PartNumberDefinitionResponse> {
    let result: PartNumberDefinitionResponse = null;

    try {
      const response = await firstValueFrom(
          this.partNumberDefinitionControllerService
              .partNumberDefinitionsByPartNumber({
               partNumber
              }))

      if (response) {
        result = response;
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.PART_SERVICE, `Backend error occurred when trying to receive partNumberDefinitions from part-service controller.`, error);
    }

    return result;
  }


  public async getBlockingActions(partId:string):Promise<Array<BlockingActionResponse>>{
   return firstValueFrom(this.partControllerService.getBlockingActions({id: partId}))
  }

  public async getPartsById(idArray: string[]): Promise<PagePartResponse> {
    return  firstValueFrom(this.partControllerService.getAllPartsByIDList({
      partListRequest:
        {ids: idArray}
    },'body'))
  }


  /**
   * This method will release a blocking action on a given engine.
   * @param entityId
   * @param blockingFilter
   * @param releaseReason
   */
  public async releaseBlockingAction(entityId: string, blockingFilter: BlockingActionResponse, releaseReason: string): Promise<BlockingActionResponse> {
    try {
      const releasedBy = AuthService.userName;

      const request: BlockingActionUpdateRequest = {
        releaseReason,
        releasedBy,
        releasedAt: new Date().toISOString(),
        source: 'SZONLINEV2',
        action: blockingFilter.action,
        blockedAt: blockingFilter.blockedAt,
        blockedBy: blockingFilter.blockedBy,
        blockingReason: blockingFilter.blockingReason
      }

      const params: UpdateBlockingActionRequestParams = {
        id: entityId,
        blockingActionId: blockingFilter.id,
        blockingActionUpdateRequest: request
      }

      const result = await firstValueFrom(this.partControllerService.updateBlockingAction(params));
      return result;

    } catch (error) {
      LoggingService.logError(LoggingSource.ENGINE_SERVICE,
        `Backend error occurred when trying to release a blocking action.`, error);
    }

    return null;
  }
}
