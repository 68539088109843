<ng-container *ngIf="loggedIn && EnterpriseRoleProviderService.rolesLoaded">
  <nav id="sidebar" [class.active]="sidebarCollapsed"
       [style.margin-bottom.px]="(stageEnum === 0 || stageEnum === 1)
          && (!sidebarCollapsed && !((themingSource | async).category === 'NONE')) ?
          116 : 78" >
    <div class="sidebar-header pt-3 pl-3">

      <ng-container *opRequiredClaimByName="'home-dashboard'">
        <h4><a routerLink="/"><i class="fas fa-home" [class.fa-fw]="!sidebarCollapsed"></i>
          <span class="pl-2">Home</span>
        </a></h4>
      </ng-container>

      <ng-container *ngIf="!EnterpriseRoleProviderService.hasRequiredClaimByName('home-dashboard')">
        <h4><a routerLink="/home/info/show"><i class="fas fa-home" [class.fa-fw]="!sidebarCollapsed"></i>
          <span class="pl-2">Home</span>
        </a></h4>
      </ng-container>

    </div>

    <ul class="list-unstyled components">
      <li>
        <a [routerLink]="'/home/info/show'">
          <i class="fas fa-info-circle" [class.fa-fw]="!sidebarCollapsed"></i>
          <span>{{ 'navbar.dropdown.info' | translate }}</span>
        </a>
        <a *opRequiredClaimByName="'sfm-board-read'" [routerLink]="'/home/sfm-board'">
          <i class="far fa-eye" [class.fa-fw]="!sidebarCollapsed"></i>
          <span>{{ 'navbar.dropdown.sfm' | translate }}</span>
        </a>
        <a *opRequiredClaimByName="'tools-'"
           id="toolsSubmenuLink" [routerLink]="'/tools/home'"
           (click)="collapseOther('toolsSubmenu')">
          <i class="fas fa-toolbox" [class.fa-fw]="!sidebarCollapsed"></i>
          <span>{{ 'navbar.dropdown.tools.name' | translate }}</span>
        </a>
        <ul class="collapse list-unstyled" id="toolsSubmenu">
          <li *opRequiredClaimByName="'tools-dmc-parse'">
            <a [routerLink]="'/tools/dmc/parse'" [title]="'navbar.dropdown.parts.parse' | translate">
              <i class="fas fa-glasses fa-ws" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.parts.parse' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'tools-label-scan-camera'">
            <a [routerLink]="'/tools/dmc/scan'" [queryParams]="{ mode: 'camera', activeTab: 'all' }"
              [title]="'navbar.dropdown.parts.scanDmc' | translate">
              <i class="fas fa-photo-video fa-ws" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.parts.scanDmc' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'tools-label-scan-text'">
            <a [routerLink]="'/tools/dmc/scan'" [queryParams]="{ mode: 'input', activeTab: 'all' }"
              [title]="'navbar.dropdown.parts.scanDmc' | translate">
              <i class="fas fa-photo-video fa-ws" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.parts.scanDmcExtern' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'tools-stack-check'">
            <a [routerLink]="'/tools/stack-check'"
               [title]="'navbar.dropdown.tools.stack-check' | translate">
              <i class="fab fa-stack-overflow pr-1" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.tools.stack-check' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'tools-entity-check'">
            <a [routerLink]="'/tools/entity-check'"
               [title]="'navbar.dropdown.tools.entity-check' | translate">
              <i class="fas fa-cogs fa-ws" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.tools.entity-check' | translate }}</span>
            </a>
          </li>
        </ul>
        <a *opRequiredClaimByName="'part-list-search'" [routerLink]="'/part/parts'">
          <i class="fas fa-cog" [class.fa-fw]="!sidebarCollapsed"></i>
          <span>{{ 'navbar.dropdown.parts.parts' | translate }}</span>
        </a>
        <a *opRequiredClaimByName="'engine-list-search'" [routerLink]="'/engine/list'">
          <i class="fas fa-cogs" [class.fa-fw]="!sidebarCollapsed"></i>
          <span>{{ 'navbar.dropdown.engines.name' | translate }}</span>
        </a>
        <ng-container *opRequiredClaimByFunctionalResource="'actions-'">
        <a id="actionSubmenuLink" [routerLink]="'actions/home'"
          (click)="collapseOther('actionSubmenu')">
          <i class="fas fa-tasks" [class.fa-fw]="!sidebarCollapsed"></i>
          <span>{{ 'navbar.dropdown.actions.name' | translate }}</span>
        </a>
        <ul class="collapse list-unstyled" id="actionSubmenu">
          <li *opRequiredClaimByName="'action-measurement-collect'">
            <a [routerLink]="'/actions/msc'" [title]="'navbar.dropdown.actions.measurementexport'">
              <i class="fas fa-file-export fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.actions.measurementexport' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'action-process-data-collect'">
            <a [routerLink]="'/actions/pds'" [title]="'navbar.dropdown.actions.processDataSetsExport'">
              <i class="fas fa-chalkboard-teacher fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.actions.processDataSetsExport' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'action-blockings-list'">
            <a [routerLink]="'/actions/blockings'" [title]="'navbar.dropdown.actions.blocking'">
              <i class="fas fa-ban fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.actions.blocking' | translate }}</span>
            </a>
          </li>

            <li *opRequiredClaimByName="'action-filter-list'">
              <a [routerLink]="'/actions/filter'"
                 [title]="'navbar.dropdown.tracking.filters' | translate">
                <i class="fas fa-filter fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
                <span>{{ 'navbar.dropdown.tracking.filters' | translate }}</span>
              </a>
            </li>

          <li *opRequiredClaimByName="'action-parent-search-collect'">
            <a [routerLink]="'/actions/bulk-psc'"
               [title]="'navbar.dropdown.actions.parent-search' | translate">
              <i class="fas fa-child fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.actions.parent-search' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'action-assembly-search-collect'">
            <a [routerLink]="'/actions/ascv2'"
              [title]="'navbar.dropdown.actions.assembly-search' | translate">
              <i class="fas fa-expand  fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.actions.assembly-search' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'action-engine-search-collect'">
            <a [routerLink]="'/actions/escv2'"
              [title]="'navbar.dropdown.actions.engine-search' | translate">
              <i class="fas fa-expand-alt  fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.actions.engine-search' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'action-stack-search-collect'">
            <a [routerLink]="'/actions/sscv2'"
              [title]="'navbar.dropdown.actions.stackidentifier-search' | translate">
              <i class="fas fa-shipping-fast  fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.actions.stackidentifier-search' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'action-tool-correction-list'">
            <a [routerLink]="'/actions/toolcorrection'"
              [title]="'navbar.dropdown.actions.toolcorrection' | translate">
              <i class="fas fa-tools fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.actions.toolcorrection' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'build-ability-check-programs-read'">
            <a [routerLink]="'/actions/build-program-planning'"
               [title]="'navbar.dropdown.actions.build-program-planning' | translate">
              <i class="fas fa-th fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.actions.build-program-planning' | translate }}</span>
            </a>
          </li>
            <li *opRequiredClaimByName="'build-ability-check-analyses'">
              <a [routerLink]="'/actions/build-ability-check/profile'" [title]="'i18n.component.build-ability-check.sidebar-entry' | translate">
                <i class="fas fa-dumpster-fire fa-fw" [class.subMenuIcon]="sidebarCollapsed"></i>
                <span>{{ 'i18n.component.build-ability-check.sidebar-entry' | translate }}</span>
              </a>
            </li>
        </ul>
        </ng-container>
        <ng-container *opRequiredClaimByFunctionalResource="'tracking-'">
        <a id="tracingSubmenuLink" [routerLink]="'tracking/home'" (click)="collapseOther('tracingSubmenu')">
          <i class="fas fa-shoe-prints " [class.fa-fw]="!sidebarCollapsed"></i>
          <span>{{ 'navbar.dropdown.tracking.name' | translate }}</span>
        </a>
        <ul class="collapse list-unstyled" id="tracingSubmenu">
          <li *opRequiredClaimByName="'tracking-events-list'">
            <a [routerLink]="'/tracking/event-list'" [title]="'navbar.dropdown.tracking.events' | translate">
              <i class="fas fa-binoculars" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.tracking.events' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'tracking-daily-program-list'">
            <a [routerLink]="'/tracking/daily-program'" [title]="'navbar.dropdown.tracking.dailyProgram' | translate">
              <i class="fas fa-calendar-day" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.tracking.dailyProgram' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'tracking-stack-list'">
            <a [routerLink]="'/tracking/stacks'" [title]="'navbar.dropdown.tracking.stacks' | translate">
              <i class="fab fa-stack-overflow" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.tracking.stacks' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'tracking-warehouse-list'">
            <a [routerLink]="'/tracking/warehouse'" [title]="'i18n.globals.logistics.warehouse-view' | translate">
              <i class="fas fa-warehouse" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'i18n.globals.logistics.warehouse-view' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'tracking-stacking-app-show'">
            <a [routerLink]="'/tracking/loadings'" [title]="'navbar.dropdown.tracking.loadings' | translate">
              <i class="fas fa-dolly" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.tracking.loadings' | translate }}</span>
            </a>
          </li>
        </ul>
        </ng-container>
        <ng-container *opRequiredClaimByFunctionalResource="'statistics-'">
            <a id="statisticsSubmenuLink" [routerLink]="'/statistics/home'" (click)="collapseOther('statisticsSubmenu')">
              <i class="fas fa-chart-line" [class.fa-fw]="!sidebarCollapsed"></i>
              <span>{{ 'navbar.statistics' | translate}}</span>
            </a>
        <ul class="collapse list-unstyled" id="statisticsSubmenu">
          <li *opRequiredClaimByName="'statistics-output'">
            <a [routerLink]="'/statistics/output'" [title]="'navbar.dropdown.statistics.output' | translate">
              <i class="fas fa-chart-bar fa-ws" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.statistics.output' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'statistics-oee'" >
            <a [routerLink]="'/statistics/oee'" [title]="'navbar.dropdown.statistics.oee' | translate">
              <i class="fas fa-percent fa-ws" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.statistics.oee' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'statistics-quickView'">
            <a [routerLink]="'/statistics/quickview'" [title]="'navbar.dropdown.statistics.quickview' | translate">
              <i class="fas fa-tv fa-ws" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.statistics.quickview' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'statistics-workload'">
            <a [routerLink]="'/statistics/workload'" [title]="'navbar.dropdown.statistics.workload' | translate">
              <i class="fas fa-balance-scale fa-ws" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.statistics.workload' | translate }}</span>
            </a>
          </li>
        </ul>
        </ng-container>

        <!-- TODO: Add User / Admin Check for FCT Role -->
        <ng-container *opRequiredClaimByName="'feat-statistics'">
          <a id="fctSubmenuLink" [routerLink]="'/fct/home'"
             (click)="collapseOther('fctSubmenu')">
            <i class="fas fa-exclamation-circle" [class.fa-fw]="!sidebarCollapsed"></i>
            <span>{{'navbar.dropdown.fct.fct' | translate}}</span>
          </a>

          <ul class="collapse list-unstyled" id="fctSubmenu">
            <li *opRequiredClaimByName="'feat-admin'">
              <a [routerLink]="'/fct/fct-admin'"
                 [title]="'navbar.dropdown.fct.fct-admin' | translate">
                <i class="fas fa-user-cog" [class.subMenuIcon]="sidebarCollapsed"></i>
                <span>{{'navbar.dropdown.fct.fct-admin' | translate}}</span>
              </a>
            </li>
            <li>
              <a [routerLink]="'/fct/fct-statistics'"
                 [title]="'navbar.dropdown.fct.fct-statistics' | translate">
                <i class="fas fa-chart-bar" [class.subMenuIcon]="sidebarCollapsed"></i>
                <span>{{'navbar.dropdown.fct.fct-statistics' | translate}}</span>
              </a>
            </li>
            <li *opRequiredClaimByName="'feat-register'">
              <a [routerLink]="'/fct/fct-user'"
                 [title]="'navbar.dropdown.fct.fct-user' | translate">
                <i class="fas fa-user" [class.subMenuIcon]="sidebarCollapsed"></i>
                <span>{{'navbar.dropdown.fct.fct-user' | translate}}</span>
              </a>
            </li>
          </ul>
        </ng-container>
        <!-- TODO: FCT END -->

        <ng-container *opRequiredClaimByFunctionalResource="'metadata-'">
        <a id="metadataSubmenuLink" [routerLink]="'/metadata/home'" (click)="collapseOther('metadataSubmenu')">
          <i class="fas fa-database " [class.fa-fw]="!sidebarCollapsed"></i>
          <span>Metadata</span>
        </a>
        <ul class="collapse list-unstyled" id="metadataSubmenu">
          <li *opRequiredClaimByName="'metadata-cost-centers-list'">
            <a [routerLink]="'/metadata/cost-center'" [title]="'navbar.dropdown.metadata.costcenter.name' | translate">
              <i class="fas fa-box" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.metadata.costcenter.name' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'metadata-product-unit-list'">
            <a [routerLink]="'/metadata/product-unit'" [title]="'navbar.dropdown.metadata.productunit' | translate">
              <i class="fas fa-mountain" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.metadata.productunit' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'metadata-product-center-list'">
            <a [routerLink]="'/metadata/product-center'" [title]="'navbar.dropdown.metadata.productcenter' | translate">
              <i class="fas fa-truck-pickup" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.metadata.productcenter' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'metadata-shift-plans-list'">
            <a [routerLink]="'/metadata/work-shift'" [title]="'navbar.dropdown.metadata.workshifts' | translate">
              <i class="fas fa-clock" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.metadata.workshifts' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'metadata-part-numbers-list'">
            <a [routerLink]="'/metadata/partnumberdefinitions'"
              [title]="'navbar.dropdown.metadata.partnumbers' | translate">
              <i class="fas fa-list-ol" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.metadata.partnumbers' | translate }}</span>
            </a>
          </li>
        </ul>
        </ng-container>

        <ng-container *opRequiredRole="'role_assetmanagement_user'">
          <a id="assetSubmenuLink" [routerLink]="'/asset/list'"
            (click)="collapseOther('assetSubmenu')">
            <i class="fas fa-luggage-cart" [class.fa-fw]="!sidebarCollapsed"></i>
            <span>{{ 'navbar.dropdown.shopfloorAssetManagement.name' | translate }}</span>
          </a>
          <ul class="collapse list-unstyled" id="assetSubmenu">
            <li>
              <a [routerLink]="'/asset/list'" [title]="'navbar.dropdown.shopfloorAssetManagement.list' | translate">
                <i class="fas fa-newspaper" [class.subMenuIcon]="sidebarCollapsed"></i>
                <span>{{ 'navbar.dropdown.shopfloorAssetManagement.list' | translate }}</span>
              </a>
            </li>
            <li>
              <a [routerLink]="'/asset/type'" [title]="'navbar.dropdown.shopfloorAssetManagement.types' | translate">
                <i class="fas fa-tags" [class.subMenuIcon]="sidebarCollapsed"></i>
                <span>{{ 'navbar.dropdown.shopfloorAssetManagement.types' | translate }}</span>
              </a>
            </li>
          </ul>
        </ng-container>

        <ng-container *opRequiredRole="'role_virtualwarehouse_user'">
          <a [routerLink]="'/usecases/vwh'"
             [title]="'navbar.dropdown.usecases.virtual-warehouse' | translate">
            <i class="fas fa-warehouse" [class.subMenuIcon]="sidebarCollapsed"></i>
            <span>{{ 'navbar.dropdown.usecases.virtual-warehouse' | translate }}</span>
          </a>
        </ng-container>

        <ng-container *opRequiredClaimByName="'usecase-read'">
          <a id="usecaseSubmenuLink" [routerLink]="'usecases/home'"
             (click)="collapseOther('usecaseSubmenu')">
            <i class="fas fa-boxes " [class.fa-fw]="!sidebarCollapsed"></i>
            <span>{{ 'navbar.dropdown.usecases.name' | translate }}</span>
          </a>
          <ul class="collapse list-unstyled" id="usecaseSubmenu">
            <li>
              <a [routerLink]="'/usecases/camerasz'" title="camerasz">
                <i class="fas fa-camera-retro" [class.subMenuIcon]="sidebarCollapsed"></i>
                <span>Camera-SZ</span>
              </a>
            </li>
            <li>
              <a [routerLink]="'/usecases/measurement-protocols'"
                 [title]="'navbar.dropdown.tracking.measurement-protocols' | translate">
                <i class="fas fa-chart-line" [class.subMenuIcon]="sidebarCollapsed"></i>
                <span>{{ 'navbar.dropdown.tracking.measurement-protocols' | translate }}</span>
              </a>
            </li>
          </ul>
        </ng-container>
      </li>
      <hr>
      <li>
        <a id="adminSubmenuLink" [routerLink]="'admin/home'" (click)="collapseOther('adminSubmenu')">
          <i class="fas fa-wrench " [class.fa-fw]="!sidebarCollapsed"></i>
          <span>System</span>
        </a>
        <ul class="collapse list-unstyled" id="adminSubmenu">
          <li>
            <a *opRequiredClaimByName="'news-create'"
               [routerLink]="'/home/info/list'" [title]="'navbar.dropdown.info' | translate">
              <i class="fas fa-newspaper" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.info' | translate }}</span>
            </a>
          </li>
          <li>
            <a *opRequiredRole="'role_admin'" [routerLink]="'/admin/apps'"
              [title]="'navbar.dropdown.system.applicationManagement' | translate">
              <i class="fas fa-shapes" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.system.applicationManagement' | translate }}</span>
            </a>
          </li>
          <li>
            <a *opRequiredRole="'role_admin'" [routerLink]="'/admin/procedures'"
              [title]="'navbar.dropdown.system.opsManagement' | translate">
              <i class="fas fa-stream" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.system.opsManagement' | translate }}</span>
            </a>
          </li>
          <li>
            <a *opRequiredRole="'role_admin'" [routerLink]="'/admin/dlq'" [title]="'navbar.dropdown.system.dlq' | translate">
              <i class="fas fa-book-dead" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.system.dlq' | translate }}</span>
            </a>
          </li>
          <li *opRequiredClaimByName="'system-service-monitor'">
            <a [routerLink]="'/admin/status'" [title]="'navbar.dropdown.system.servicestatusoverview' | translate">
              <i class="fab fa-watchman-monitoring" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.system.servicestatusoverview' | translate }}</span>
            </a>
          </li>
          <li>
            <a *opRequiredRole="'role_admin'" [routerLink]="'/admin/jobs'" [title]="'navbar.dropdown.system.jobs' | translate">
              <i class="fas fa-gamepad" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.system.jobs' | translate }}</span>
            </a>
          </li>
          <li>
            <a *opRequiredRole="'role_admin'" [routerLink]="'/admin/telegram'" [title]="'navbar.dropdown.system.telegrams' | translate">
              <i class="fab fa-telegram-plane" [class.subMenuIcon]="sidebarCollapsed"></i>
              <span>{{ 'navbar.dropdown.system.telegrams' | translate }}</span>
            </a>
          </li>
        </ul>
        <div id="sidebarToggleContainer">
          <ul class="list-unstyled">
            <ng-container *opRequiredClaimByFunctionalResource="['home-dashboard', 'statistics-']">
            <li>
              <ng-container *ngIf="themingSource | async as theming">
                <ng-container *ngIf="selectedThemeSource | async as selection">
                <div *ngIf="theming.themes.length > 0"
                     [hidden]="sidebarCollapsed"
                     class="border-right border-top">
                  <ul class="list-unstyled">
                    <li>
                      <button id="settingsSubmenuLink" class="pointer btn btn-light btn-block"
                              (click)="collapseOther('settingsSubmenu')">
                        <i class="fas fa-tasks" [class.fa-fw]="!sidebarCollapsed"></i>
                        <span>{{ 'navbar.dropdown.settings.name' | translate }}</span>
                      </button>
                    </li>
                  </ul>

                  <ul class="collapse list-unstyled" id="settingsSubmenu">
                    <ng-container *ngFor="let themeSet of theming.themes">
                      <li>
                        <a>{{ themeSet.type.key | translate }}</a>
                      </li>
                      <li *ngFor="let option of themeSet.options">
                        <a style="padding-left: 80px!important;">
                          <input class="form-check-input" type="radio" [name]="themeSet.type + '_' + option.value"
                                 (change)="setThemingOption(theming.category, themeSet.type.value, option.value)"
                                 [checked]="getThemingOption(selection, theming.category, themeSet.type.value, option.value)">
                          <label class="form-check-label">{{ option.key | translate }}</label>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </div>
                </ng-container>
              </ng-container>
            </li>
            </ng-container>
          </ul>
          <ul class="list-unstyled">
            <li>
              <div id="sidebarToggle" class="border-top border-right" [class.active]="sidebarCollapsed">
                <button type="button" id="sidebarCollapse" class="btn btn-light btn-block"
                  [class.active]="sidebarCollapsed" (click)="onSideBarToggle()">
                  <i class="fas" [class.fa-chevron-left]="!sidebarCollapsed"
                    [class.fa-chevron-right]="sidebarCollapsed"></i>
                  <i class="fas" [class.fa-chevron-left]="!sidebarCollapsed"
                    [class.fa-chevron-right]="sidebarCollapsed"></i>
                  <span class="pl-1">{{ 'global.word.collapse' | translate }}</span>
                </button>
              </div>
            </li>
          </ul>
        </div>
    </ul>
  </nav>
</ng-container>
